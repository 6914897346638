.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 882px;
    width: 100%;
    padding: 0px 45px;
    z-index: auto;
    .closeIcon {
        justify-content: flex-end;
        display: flex;
        margin-right: 20px;
        margin-top: 10px;
        cursor: pointer;
        position: relative;
        z-index: 9;
    }
    .innerwrap {
        width: 100%;
        max-width: 882px;
        background: #F7F7F7;
        box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
        border-radius: 20px;
        padding: 32px;
        overflow: hidden;
        position: relative;

        @keyframes cloud-1 {
            0% {
                left: -25%;
                top: 25px;
            }

            25% {
                top: 40px;
            }

            50% {
                top: 10px;
            }

            70% {
                top: 40px;
            }

            100% {
                left: 100%;
                top: 25px;
            }
        }

        @keyframes cloud-2 {
            0% {
                right: -20%;
                bottom: 10px;
            }

            25% {
                bottom: 40px;
            }

            50% {
                bottom: 10px;
            }

            70% {
                bottom: 40px;
            }

            100% {
                right: 100%;
                bottom: 25px;
            }
        }

        @keyframes cloud-3 {
            0% {
                right: -30%;
                top: 25px;
            }

            25% {
                top: 40px;
            }

            50% {
                top: 10px;
            }

            70% {
                top: 40px;
            }

            100% {
                right: 100%;
                top: 25px;
            }
        }

        @keyframes birds-flap {
            0% {
                transform: translateY(-10px);
            }

            50% {
                transform: translateY(30px);
            }

            100% {
                transform: translateY(-10px);

            }
        }

        .bg {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;

            .clouds {
                position: absolute;

                &.cloud_1 {
                    left: -25%;
                    top: 25px;
                    animation: cloud-1 25s linear infinite;
                }

                &.cloud_2 {
                    right: -20%;
                    bottom: 10px;
                    animation: cloud-2 45s linear infinite;
                }

                &.cloud_3 {
                    right: -30%;
                    top: 10px;
                    animation: cloud-3 30s linear infinite;
                }
            }
        }

        .content {
            position: relative;
            z-index: 2;
            text-align: center;

            .logoicon {
                position: relative;

                img {
                    transform: translateY(-10px);
                    animation: birds-flap 5s ease infinite;
                }
            }

            h2 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                color: #242526;
                line-height: normal;
                margin: 25px 0px 30px 0px;
            }

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 23px;
                color: #F35597;
                margin-bottom: 60px;
                max-width: 635px;
                display: inline-block;
            }

            @media screen and (max-width:601px) {
                h2 {
                    font-size: 23px;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
        }

    }
}
.loadingSRP {
    max-width: 551px;
    padding: unset;
    .innerwrap {
        padding: unset;
        background: var(--color-white);
        .lottieContainer {
            position: unset !important;
            height: 150px;
        }
        .content {
            h2 {
                font-weight: 600;
                font-size: 23px;
                color: var(--color-black);
                line-height: 120%;
                margin: 10px 0px 10px 0px;
            }
            p {
                font-weight: 400;
                font-size: 18px;
                color: var(--color-dark-grey);
                line-height: 120%;
                margin-bottom: 30px;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .loading {
        .innerwrap {
            @keyframes cloud-1 {
                0% {
                    left: -230px;
                    top: 25px;
                }

                25% {
                    top: 40px;
                }

                50% {
                    top: 10px;
                }

                70% {
                    top: 40px;
                }

                100% {
                    left: 100%;
                    top: 25px;
                }
            }

            @keyframes cloud-2 {
                0% {
                    right: -220px;
                    bottom: 10px;
                }

                25% {
                    bottom: 40px;
                }

                50% {
                    bottom: 10px;
                }

                70% {
                    bottom: 40px;
                }

                100% {
                    right: 100%;
                    bottom: 25px;
                }
            }

            @keyframes cloud-3 {
                0% {
                    right: -330px;
                    top: 25px;
                }

                25% {
                    top: 40px;
                }

                50% {
                    top: 10px;
                }

                70% {
                    top: 40px;
                }

                100% {
                    right: 100%;
                    top: 25px;
                }
            }

            .bg {
                .clouds {
                    &.cloud_1 {
                        left: -230px;
                        top: 25px;
                        animation: cloud-1 25s linear infinite;
                    }

                    &.cloud_2 {
                        right: -220px;
                        bottom: 10px;
                        animation: cloud-2 45s linear infinite;
                    }

                    &.cloud_3 {
                        right: -330px;
                        top: 10px;
                        animation: cloud-3 30s linear infinite;
                    }
                }
            }
        }
    }
    .loadingSRP {
        padding: 0px 45px;
        .content {
            p {
                font-size: 14px;
                font-weight: 400;
                width: 219px;
            }
        }
    }
}